import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import "../../styles/globals.css";
import { MetaComponent } from "../../components";
import {
  Button,
  SassyText,
  Title,
  Section,
  Text,
  StyledLink,
  Accordion,
  SectionWrapper,
  HeroSection,
} from "../../components/core";
import { useMixpanel } from "../../context/MixpanelContext";
import useHindiLangFont from "../../hooks/useHindiLangFont";
import { noOfClaims } from "../../utils/variables";
import { LeadFormProvider } from "../../context/LeadFormContext";

import LiabilityArt from "../../assets/images/liability.svg";
import DNOSmall from "../../assets/images/dno_small.svg";
import ENOSmall from "../../assets/images/eno_small.svg";
import WorkmenSmall from "../../assets/images/workmens_small.svg";
import PublicSmall from "../../assets/images/public_small.svg";
import Handshake from "../../assets/images/handshake.svg";
import Bullet from "../../assets/images/bullet.svg";

const HelpSection = loadable(() => import("../../components/HelpSection"));

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const Waves = loadable(() => import("../../components/core"), {
  resolveComponent: (components) => components.Waves,
});

const Liability = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['liability', 'common'])
  const mixpanel = useMixpanel();
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName);
    handleQuoteModalOpen();
  };

  const trackMixpanel = (event) => {
    mixpanel.track(event);
  };

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Business Liability Insurance - Verak"
        description="Faulty products, a customer getting hurt, or an executive making errors are common liabilities. Protect your business with Liability Insurance. Get a Quote"
        image="liability"
        url="liability"
      />
      <Navbar active="products" />
      {/* Add z-index: 1 to make waves a bit darker */}
      <Section background="rgba(255, 238, 227, 0.5)">
        <Waves />
        <SectionWrapper>
          <HeroSection className="display-flex flex-direction-row">
            <HeaderContainer>
              <Title
                fontSize="64px"
                lineHeight="76px"
                style={{ paddingBottom: "50px" }}
                className={hindiBlack}
              >
                <Trans i18nKey="Title">
                  Secure your <br />
                  business against <br />
                  <SassyText>Unforseen Liabilities</SassyText>
                </Trans>
              </Title>
              <HeroContainer>
                <LiabilityArt className="liability-hero-mobile" />
              </HeroContainer>
              <Text style={{ paddingBottom: "50px" }} className={hindiRegular}>
                {t('Subtitle')}
              </Text>
              <Button
                wide
                label={t('CTALabels.GetAQuote')}
                onClick={() =>
                  handleGetQuoteClick(
                    "Clicked-Top - 'Get a Quote' - Liability LP"
                  )
                }
                className={hindiBold}
              />
            </HeaderContainer>
            <HeroContainer>
              <LiabilityArt className="liability-hero-desktop" />
            </HeroContainer>
          </HeroSection>
        </SectionWrapper>
      </Section>
      <Section background="#ffffff">
        <SectionWrapper>
          <Title fontSize="36px" className={hindiBold}>
            {t('Policies.Title')}
          </Title>
          <Container>
            <CardContainer>
              <OverflowContainer>
                <StyledCard
                  to="/liability/directorsandofficers"
                  onClick={() =>
                    trackMixpanel("Clicked Directors & Officers Liability Insurance")
                  }
                >
                  <DNOSmall className="icon" />
                  <h5 className={hindiBold}>{t('Policies.D&O')}</h5>
                </StyledCard>
                <StyledCard
                  to="/liability/errorsandomissions"
                  onClick={() => trackMixpanel("Clicked Errors & Omissions Insurance")}
                >
                  <ENOSmall className="icon" />
                  <h5 className={hindiBold}>{t('Policies.E&O')}</h5>
                </StyledCard>
                <StyledCard
                  to="/liability/workmen_compensation"
                  onClick={() =>
                    trackMixpanel("Clicked Workmen's Compensation Insurance")
                  }
                >
                  <WorkmenSmall className="icon" />
                  <h5 className={hindiBold}>{t('Policies.WC')}</h5>
                </StyledCard>
                <StyledCard
                  to="/liability/public_liability"
                  onClick={() => trackMixpanel("Clicked Public Liability Insurance")}
                >
                  <PublicSmall className="icon" />
                    <h5 className={hindiBold}>
                      <Trans i18nKey="Policies.PL">
                        Public
                        <br /> Liability
                        <br /> Insurance
                      </Trans>
                    </h5>
                </StyledCard>
              </OverflowContainer>
            </CardContainer>
          </Container>
        </SectionWrapper>
      </Section>
      <Section background="#FFF7F1">
        <SectionWrapper>
          <Title style={{ paddingBottom: "20px" }} className={hindiBold}>{t('WhyChooseUs.Title')}</Title>
          <Left>
            <Text mfontSize="16px" className={hindiRegular}>
              {t('WhyChooseUs.Subtitle')}
            </Text>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    <Trans i18nKey="WhyChooseUs.PoweredByTech.Text">We’re powered by <SassyText>TECHNOLOGY</SassyText></Trans>
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ marginTop: "15px", marginLeft: "30px" }}
                    className={hindiRegular}
                  >
                    {t('WhyChooseUs.PoweredByTech.Subtext')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    <Trans i18nKey="WhyChooseUs.DoWhatMatters.Text">We do what <SassyText>MATTERS</SassyText></Trans>
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ marginTop: "15px", marginLeft: "30px" }}
                    className={hindiRegular}
                  >
                    {t('WhyChooseUs.DoWhatMatters.Subtext', {noOfClaims: noOfClaims})}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    <Trans i18nKey="WhyChooseUs.Numbers.Text">Numbers <SassyText>SPEAK</SassyText></Trans>
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    mfontSize="16px"
                    style={{ marginTop: "15px", marginLeft: "30px" }}
                    className={hindiRegular}
                  >
                    {t('WhyChooseUs.Numbers.Subtext')}
                  </Text>
                </li>
              </ul>
            </Container>
          </Left>
        </SectionWrapper>
      </Section>
      <Section background="#fff">
        <SectionWrapper>
          <Title style={{ paddingBottom: "50px" }} className={hindiBold}>
            {t('AboutLiabilityIns.Title')}
          </Title>
          <Left>
            <Column>
              <Accordion
                title={t('AboutLiabilityIns.Accordion1.Q')}
                content={
                  <>
                    <Trans i18nKey="AboutLiabilityIns.Accordion1.A.Accidents">
                      <strong className={hindiBold}>Accidents always occur unexpectedly.</strong> And
                      these unforeseen events can lead to major lawsuits, not to
                      mention financial and reputational ruin. Despite safety
                      measures, your business could face the following:
                    </Trans>
                    
                    <br />
                    <ul>
                      <li className={hindiRegular}>
                        {t('AboutLiabilityIns.Accordion1.A.Prob1')}
                      </li>
                      <li className={hindiRegular}>
                        {t('AboutLiabilityIns.Accordion1.A.Prob2')}
                      </li>
                      <li className={hindiRegular}>
                        {t('AboutLiabilityIns.Accordion1.A.Prob3')}
                      </li>
                    </ul>
                    <br />
                    <Trans i18nKey="AboutLiabilityIns.Accordion1.A.HowLiabilityInsHelps">
                      Such events can absorb large amounts of money for the
                      purpose of defence, payable damages, etc. But,{" "}
                      <strong>
                        with a Liability Insurance policy in your arsenal, you can
                        rest easy and let the insurance company indemnify you for
                        your losses.
                      </strong>
                    </Trans>
                    
                  </>
                }
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
              <Accordion
                title={t('AboutLiabilityIns.Accordion2.Q')}
                content={
                  <>
                    <Trans i18nKey="AboutLiabilityIns.Accordion2.A.WhatIsCovered">
                      Liability Insurance covers 
                      <strong> a business organization or a professional </strong>
                      in the event of <strong>a lawsuit or legal claim </strong>
                      made against them by a third-party on grounds of 
                      <strong>
                        bodily harm, property damage, wrongdoing, or negligence.
                      </strong>
                    </Trans>
                    <br />
                    <br />
                    <Trans i18nKey="AboutLiabilityIns.Accordion2.A.HowDoesItHelp">
                      Liability Insurance helps 
                      <strong>
                        indemnify the business of its legal liability, pay
                        compensation to the claimant, and cover costs of defence.
                      </strong>
                    </Trans>
                  </>
                }
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
              <Accordion
                title={t('AboutLiabilityIns.Accordion3.Q')}
                content={
                  <>
                    <strong>
                      {t('AboutLiabilityIns.Accordion3.A.WhoShouldOpt')}
                    </strong> 
                    {t('AboutLiabilityIns.Accordion3.A.WhyToOpt')}
                    <br />
                    <br />
                    <Trans i18nKey="AboutLiabilityIns.Accordion3.A.SomePoliciesCompulsory">
                      On top of that, 
                      <strong>
                        certain Liability policies have been made compulsory by
                        the government. 
                      </strong>
                    </Trans>
                    {t('AboutLiabilityIns.Accordion3.A.Example')}
                  </>
                }
                titleClassName={hindiBold}
                contentClassName={hindiRegular}
              />
            </Column>
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Section background="#fff">
        <SectionWrapper>
          <StressContainer>
            <div>
              <Title style={{ marginBottom: "40px" }} className={hindiBlack}>
                <Trans i18nKey="StressFreeIns">
                  Ready to take the
                  <br />
                  <SassyText>stress out of business insurance?</SassyText>
                </Trans>
              </Title>
              <div className="on-desktop">
                <Button
                  wide
                  label={t('CTALabels.GetAQuote')}
                  onClick={() =>
                    handleGetQuoteClick(
                      "Clicked-Down - 'Get a Quote' - Liability LP"
                    )
                  }
                  className={hindiBold}
                />
              </div>
            </div>
            <Handshake className="handshake-art" />
            <div className="on-mobile">
              <Button
                wide
                label={t('CTALabels.GetAQuote')}
                onClick={() =>
                  handleGetQuoteClick(
                    "Clicked-Down - 'Get a Quote' - Liability LP"
                  )
                }
                className={hindiBold}
              />
            </div>
          </StressContainer>
        </SectionWrapper>
      </Section>
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["liability", "index", "common", "navbar", "footer", "getQuoteModal", "helpSection"]
        }, 
        language: {eq: $language 
      }
    }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100vw;
    overflow-x: auto;
  }
`;

const OverflowContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    width: fit-content;
  }
`;

const StressContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: row;

  .handshake-art {
    margin-left: 80px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    button {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
    }
    .handshake-art {
      margin-left: auto;
      margin-right: auto;
      width: 220px;
    }
  }
`;

const Left = styled.div`
  text-align: left;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCard = styled(StyledLink)`
  width: 25%;
  height: 380px;
  margin: 0 8px;
  background: #ffffff;
  border: 0.5px solid rgba(139, 142, 209, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  transition: all 0.1s ease-in;

  &:hover {
    border: 0.5px solid #2a2b7f;
  }

  h5 {
    padding: 35px 20px;
    color: #4c4c4c;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 1px;
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
    color: #6d6d6d;
    padding: 0px 40px;
  }

  .icon {
    margin-top: 25px;
  }

  @media screen and (max-width: 1000px) {
    height: 250px;

    h5 {
      font-size: 18px;
    }

    .icon {
      width: 100px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 200px;
    height: 250px;

    h5 {
      font-size: 18px;
    }

    .icon {
      width: 100px;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0px;

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

const HeroContainer = styled.div`
  .liability-hero-mobile {
    width: 0px;
    height: 0px;
    visibility: hidden;

    @media screen and (max-width: 768px) {
      margin-right: auto;
      margin-left: auto;
      visibility: visible;
      width: 230px;
      height: 250px;
    }
  }

  .liability-hero-desktop {
    width: 442px;
    height: 450px;

    @media screen and (max-width: 768px) {
      width: 0px;
      height: 0px;
      visibility: hidden;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 768px) {
    width: 100%;

    button {
      margin-left: auto;
      margin-right: auto;
    }

    p {
      padding-bottom: 10px !important;
    }
  }
`;

export default Liability;
